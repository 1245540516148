import React, { useState } from "react"
import { navigate } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Pagination from "@material-ui/lab/Pagination"
import Skeleton from "@material-ui/lab/Skeleton"
import Chip from "@material-ui/core/Chip"

import { GiLightBulb, GiPerson } from "react-icons/gi"
import { FcPodiumWithSpeaker, FcEngineering } from "react-icons/fc"
import { FaDollarSign, FaHandsHelping } from "react-icons/fa"
import { AiFillStar } from "react-icons/ai"
import { BsPencil } from "react-icons/bs"

import { usePersons } from "../hooks/use-persons"

import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"

const personTagIcons = {
  entrepreneur: <GiLightBulb size={20} />,
  executive: <GiPerson size={20} />,
  politician: <FcPodiumWithSpeaker size={20} />,
  author: <BsPencil size={20} />,
  "venture capitalist": <FaDollarSign size={20} />,
  "media personality": <AiFillStar size={20} />,
  philanthropist: <FaHandsHelping size={20} />,
  engineer: <FcEngineering size={20} />
}

function Persons() {
  const persons = usePersons()
  const personsPerPage = 24
  const [page, setPage] = useState(1)
  const [tag, setTag] = useState("")

  let personsToDisplay = persons
  if (tag !== "") {
    personsToDisplay = persons.filter(person => person.tags.includes(tag))
  }

  const handlePersonClick = alias => {
    navigate("/persons/" + alias, { state: { alias: alias } })
    setPage(1)
  }

  const handleTagClick = tag => {
    setPage(1)
    setTag(tag)
  }

  return (
    <>
      <SEO />
      <Header />
      <div
        style={{
          marginTop: "75px",
          paddingBottom: "25px",
          backgroundColor: "whitesmoke"
        }}
      >
        <Container style={{ paddingTop: 16 }}>
          <Typography variant="h5" style={{ marginBottom: "8px" }}>
            {persons.length === 0 ? "Loading all " : "Showing all "}
            {tag === ""
              ? "people"
              : tag === "media personality"
              ? "media personalities"
              : tag + "s"}
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              marginBottom: 8
            }}
          >
            {Object.keys(personTagIcons).map(person_tag => (
              <Chip
                size="small"
                style={{ margin: "0px 4px 8px" }}
                label={person_tag}
                variant={tag === person_tag ? "default" : "outlined"}
                color="primary"
                icon={personTagIcons[person_tag]}
                onClick={() => handleTagClick(person_tag)}
              />
            ))}
          </div>
          <Grid container spacing={4}>
            {persons.length === 0
              ? [...Array(personsPerPage)].map(temp => (
                  <Grid
                    container
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    key={temp}
                  >
                    <Card style={{ borderRadius: 10, borderColor: "#496BA4" }}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        height={220}
                        width={250}
                      />
                      <CardContent>
                        <Skeleton animation="wave" variant="text" height={25} />
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              : personsToDisplay
                  .slice(personsPerPage * (page - 1), personsPerPage * page)
                  .map(person => (
                    <Grid
                      container
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={2}
                      key={person.alias}
                    >
                      <Card
                        style={{ borderRadius: 10, borderColor: "#496BA4" }}
                      >
                        <CardActionArea
                          onClick={() => handlePersonClick(person.alias)}
                        >
                          <CardMedia
                            component="img"
                            src={require("../images/persons/" +
                              person.alias +
                              ".jpg")}
                          />

                          <CardContent
                            style={{ padding: "16px 16px 0px 16px" }}
                          >
                            <Typography>{person.name}</Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginBottom: "4px"
                            }}
                          >
                            {person.tags.map(person_tag => (
                              <Chip
                                size="small"
                                style={{
                                  marginTop: "4px",
                                  marginRight: "4px"
                                }}
                                label={person_tag}
                                variant={
                                  tag === person_tag ? "default" : "outlined"
                                }
                                color="primary"
                                icon={personTagIcons[person_tag]}
                                onClick={() => handleTagClick(person_tag)}
                              />
                            ))}
                          </div>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
          </Grid>
          <Pagination
            style={{
              marginTop: "25px",
              justifyContent: "center",
              display: "flex"
            }}
            page={page}
            count={Math.ceil(personsToDisplay.length / personsPerPage)}
            color="primary"
            onChange={(event, page) => setPage(page)}
          />
        </Container>
        )
      </div>
      <Footer />
    </>
  )
}

export default Persons
